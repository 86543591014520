export default defineNuxtRouteMiddleware((to) => {
  const {
    public: { baseUrl, auth0Config },
  } = useRuntimeConfig();
  const returnTo = encodeURI(baseUrl + "/auth/logout/success");
  if (to.path === "/auth/logout") {
    return navigateTo(
      `${auth0Config.logoutUrl}?client_id=${auth0Config.clientId}&returnTo=${returnTo}`,
      { external: true },
    );
  }
});
